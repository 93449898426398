<template>
  <div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      v-b-modal.modal-detalle
      variant="relief-info"
      block
      @click="verDetallePoste"
    >
      {{ operacion==='N' ? 'Iniciar Inspección' : operacion ==='I' ? 'Ingresar Seguimiento Inspección' : 'Finalizar Inspección' }}
    </b-button>
    <b-modal
      :id="ampliacion.id"
      :key="ampliacion.id"
      v-model="verDialogoDocumento"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      hide-footer
      modal-class="modal-primary"
      centered
      title="Inspecciones"
    >
      <inspeccion-ampliacion
        :id="ampliacion.id"
        :key="ampliacion.id"
        :inspeccion="ampliacion"
        :operacion="operacion"
        :item="item"
        @cerrar-dialog="ocultarDialogo"
      />
    </b-modal>
  </div>

</template>

<script>
import { BModal, BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import InspeccionAmpliacion from '@/components/Inspecciones/ampliaciones/InspeccionAmpliacion.vue'

export default {
  name: 'ModalDetailPoste',
  components: {
    BModal,
    BButton,
    InspeccionAmpliacion,
  },
  directives: {
    Ripple,
  },
  props: {
    ampliacion: {
      type: Object,
      required: true,
    },
    operacion: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      verDialogoDocumento: false,
    }
  },
  methods: {
    verDetallePoste() {
      this.ocultarDialogo()
      this.verDialogoDocumento = true
    },
    ocultarDialogo() {
      this.verDialogoDocumento = false
      this.$emit('refrescar-pagina')
    },
  },
}
</script>

<style scoped>
.card-poste {
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 18px;
  border: 1px solid rgb(238, 238, 238);
}
.modal{
  min-height: 50px;
  overflow-y: 50px;
}

.card-poste .text {
  color: #211f1f70;
}
</style>
