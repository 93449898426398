<template>
  <b-overlay
    :show="show"
  >
    <b-card-text>
      <h4 align="center">
        {{ operacion === 'N' ? 'Iniciar' : operacion === 'F' ? 'Finalizar': 'Seguimiento' }} Ampliación
      </h4>
      <br>
      <b-row>
        <b-col
          v-if="operacion === 'N'"
          cols="12"
        >
          <h6>Foto de Inicio Ampliación</h6>
        </b-col>
        <b-col
          v-if="operacion === 'N'"
          cols="12"
        >
          <center>
            <imagen
              :key="'inicio'"
              :leyenda="'inicio'"
              @cargar-file="imagenInicio"
            />
          </center>
        </b-col>
        <b-col
          v-if="operacion === 'I'"
          cols="12"
        >
          <h5>Tipo Seguimiento</h5>
        </b-col>
        <b-col
          v-if="operacion === 'I'"
          cols="12"
        >
          <v-select
            v-model="seguimiento"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="seguimientos"
          />
        </b-col>
        <!-- esta col funciona solo cuando se selecciona Agregar Imagen -->
        <b-col
          v-if="operacion === 'I' && seguimiento !== null"
          cols="12"
        >
          <br>
          <h6>Foto del Seguimiento</h6>
        </b-col>
        <!-- esta col funciona solo cuando se selecciona Agregar Imagen -->
        <b-col
          v-if="operacion === 'I' && seguimiento !== null"
          cols="12"
        >
          <center>
            <imagen
              :key="'seguimiento'"
              :leyenda="'seguimiento'"
              @cargar-file="imagenPanoramica"
            />
          </center>
        </b-col>
        <!-- esta col funciona solo cuando se selecciona Finalizar Insepccion -->
        <b-col
          v-if="operacion === 'F'"
          cols="12"
        >
          <div v-if="metersOfCable">
            <label for="demo-sb">Metros de Cable Finales</label>
            <b-form-spinbutton
              id="metrosCable"
              v-model="inspeccion.metros"
              min="0"
              max="200"
            />
          </div>
          <br>
          <b-form-group>
            <label>Serie Lámpara</label>
            <b-form-input
              id="serie"
              v-model="inspeccion.codigo"
              type="text"
            />
          </b-form-group>
          <b-form-group>
            <label>Grupo</label>
            <v-select
              v-model="inspeccion.grupo"
              label="nombre"
              :options="grupos"
              required
            />
          </b-form-group>
          <b-form-group>
            <label>Red de Comunicación</label>
            <v-select
              v-model="inspeccion.redComunicacion"
              label="nombre"
              :options="tecnologias"
              required
            />
          </b-form-group>
          <h6>Foto de Finalización</h6>
        </b-col>
        <!-- esta col funciona solo cuando se selecciona Finalizar Insepccion -->
        <b-col
          v-if="operacion === 'F'"
          cols="12"
        >
          <center>
            <imagen
              :key="'fin'"
              :leyenda="'fin'"
              @cargar-file="imagenFin"
            />
          </center>
        </b-col>
        <b-col cols="12">
          &nbsp;
        </b-col>
        <b-col cols="12">
          <h5>Comentarios</h5>
          <b-form-textarea
            id="comentarios"
            v-model="comentarios"
            rows="2"
            required
          />
        </b-col>
        <b-col
          cols="12"
          class="align-content-center"
        >
          <center>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1 align-self-center"
              @click="guardarInspeccion"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Guardar Seguimiento</span>
            </b-button>
          </center>
          <br>
        </b-col>
      </b-row>
    </b-card-text>
  </b-overlay>
</template>

<script>
import {
  BOverlay, BRow, BCol, BCardText, BFormTextarea, BButton, BFormGroup, BFormInput, BFormSpinbutton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Imagen from '@/components/Inspecciones/Imagen.vue'
import Ripple from 'vue-ripple-directive'
import { updateCreateAmpliacion } from '@/utils/inspecciones'
import { sendFile } from '@/utils/files'
import { getlistado } from '@/utils/catalogos'

export default {
  name: 'Inspeccion',
  components: {
    BOverlay,
    BRow,
    BCol,
    BCardText,
    BFormTextarea,
    Imagen,
    BButton,
    vSelect,
    BFormGroup,
    BFormInput,
    BFormSpinbutton,
  },
  fields: ['Tipo', 'Accesorio'],
  tableVariants: [
    'primary',
  ],
  striped: true,
  borderless: true,
  bordered: false,
  outlined: false,
  small: true,
  hover: false,
  dark: false,
  fixed: true,
  footClone: false,
  headVariant: null,
  noCollapse: false,
  tableVariant: 'primary',
  directives: {
    Ripple,
  },
  props: {
    inspeccion: {
      type: Object,
      required: true,
    },
    operacion: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      metersOfCable: false,
      grupos: [],
      seguimiento: null,
      seguimientos: [{ value: 'I', title: 'Inicio Proceso' }, { value: 'P', title: 'En Proceso' }, { value: 'E', title: 'Finalizado' }],
      localization: null,
      show: false,
      fotoPanoramica: '',
      comentarios: '',
      fotoInicio: '',
      fotoFin: '',
      usuario: JSON.parse(localStorage.getItem('userData')),
      tecnologias: [{
        nombre: 'JNET0',
      }, {
        nombre: 'JNET1',
      }],
    }
  },
  async created() {
    this.validarMetros()
    if (this.operacion === 'I') {
      if (!this.item.tracking) {
        this.item.tracking = []
        this.seguimientos = [{ value: 'I', title: 'Inicio Proceso' }]
      } else if (this.item.tracking.length === 1) {
        this.seguimientos = [{ value: 'P', title: 'En Proceso' }]
      } else {
        this.seguimientos = [{ value: 'E', title: 'Finalizado' }]
      }
    } else if (this.operacion === 'F') {
      this.grupos = await getlistado(12, 'Grupos', false, this.usuario)
    }
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // eslint-disable-next-line no-return-assign
        pos => (
          this.localization = {
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          }),
        error => {
          console.error('Error Obteniendo Latitud y Longitud!', error)
          this.localization = null
        },
      )
    }
  },
  methods: {
    validarMetros() {
      this.inspeccion.items.forEach(item => {
        if (!this.metersOfCable) this.validarMetrosCable(item.item)
      })
    },
    validarMetrosCable(item) {
      this.metersOfCable = item.id === 0
    },
    async guardarInspeccion() {
      if (this.fotoInicio === '' && this.operacion === 'N') {
        this.error('Debe ingresar una foto del inicio de Ampliación!')
        return
      }

      if ((this.operacion === 'I') && this.seguimiento === null) {
        this.error('Debe seleccionar un tipo de seguimiento!')
        return
      }

      if (this.operacion === 'F' && (this.inspeccion.codigo === null || this.inspeccion.codigo === '')) {
        this.error('Debe ingresar la Serie de la Lámpara!')
        return
      }

      if (this.operacion === 'F' && (this.inspeccion.grupo === null || this.inspeccion.grupo === '')) {
        this.error('Debe seleccionar un Grupo!')
        return
      }

      if (this.fotoPanoramica === '' && this.operacion === 'U' && this.seguimiento.value === 'S') {
        this.error('Debe ingresar una foto del Seguimiento!')
        return
      }
      if (this.fotoFin === '' && this.operacion === 'F') {
        this.error('Debe ingresar una foto del fin de Ampliación!')
        return
      }
      this.show = true

      const urlBucket = `ampliaciones/${this.inspeccion.id}`

      if (this.localization === null || this.localization === undefined) {
        this.error('Error obteniendo geolocalización, intente nuevamente!')
        this.show = false
        return
      }

      let imagen = null
      if (this.operacion === 'N') { // foto de inicio inspeccion
        imagen = await sendFile(this.fotoInicio, 'images', urlBucket)
      } else if (this.operacion === 'I' && (this.seguimiento.value === 'I' || this.seguimiento.value === 'P' || this.seguimiento.value === 'E')) { // foto de tracking de item
        imagen = await sendFile(this.fotoPanoramica, 'images', urlBucket)
      } else if (this.operacion === 'F') { // foto fin inspeccion
        imagen = await sendFile(this.fotoFin, 'images', urlBucket)
        if (this.inspeccion.redComunicacion) this.inspeccion.redComunicacion = this.inspeccion.redComunicacion.nombre
      }

      if (imagen !== null) {
        const file = imagen.data
        file.isMobile = window.screen.width <= 760
        file.localization = this.localization
        // eslint-disable-next-line no-nested-ternary
        file.tipo = this.operacion === 'N' ? 'Inicio Inspección' : this.operacion === 'I' ? 'Seguimiento Accesorio' : 'Finalización Ampliación'
        if (this.operacion === 'I') file.estado = this.seguimiento.title
        file.fechaHora = new Date()
        file.comentarios = this.comentarios
        if (this.operacion === 'I') {
          if (!this.item.tracking) this.item.tracking = []
          this.item.tracking.push(file)
        } else this.inspeccion.tracking.push(file)
      }
      if (this.operacion !== 'F') delete this.inspeccion.grupo
      if (this.operacion !== 'F') delete this.inspeccion.fechaInstalacion
      else this.inspeccion.fechaInstalacion = new Date()
      if (this.operacion === 'N') {
        this.inspeccion.estado = 'EN CURSO'
        const accesorio = this.inspeccion.items.shift()
        this.inspeccion.items.push(accesorio)
      } else {
        this.inspeccion.estado = this.operacion === 'F' ? 'FINALIZADA' : this.inspeccion.estado
      }

      if (this.operacion === 'I') {
        // this.inspeccion.items[this.item.id - 1] = this.item
        this.inspeccion.items.map(obj => {
          if (obj.id === this.item.id) {
            // eslint-disable-next-line no-param-reassign
            obj = this.item
          }
          return obj
        })
      }

      if (imagen !== null) {
        await updateCreateAmpliacion(this.inspeccion, 1)
        this.success('Inspección', 'Ampliación Actualizada Correctamente!!')
        this.$emit('cerrar-dialog')
        // setTimeout(window.location.reload(), 6000)
      } else {
        this.error('Error almacenando fotografía, intente nuevamente!')
      }
      this.show = false
    },
    success(titulo, mensaje) {
      this.$swal({
        title: titulo,
        text: mensaje,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    error(mensaje) {
      this.$swal({
        title: 'Error!',
        text: mensaje,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    imagenPanoramica(file) {
      this.fotoPanoramica = file
    },
    imagenInicio(file) {
      this.fotoInicio = file
    },
    imagenFin(file) {
      this.fotoFin = file
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
