<template>
  <div>
    <cards-inspecciones />
  </div>
</template>

<script>
import CardsInspecciones from '@/components/Inspecciones/ampliaciones/CardsInspecciones.vue'

export default {
  components: {
    CardsInspecciones,
  },
}
</script>
