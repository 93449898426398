<template>
  <div>
    <b-tabs
      content-class="pt-1"
      fill
    >
      <b-tab
        title="PENDIENTES"
      >
        <b-row>
          <b-col
            v-for="(inspeccion, o) in filtrarData('ASIGNADA')"
            :key="inspeccion.id"
            cols="12"
          >
            <card-inspeccion
              :id="inspeccion.id"
              :key="inspeccion.id"
              :inspeccion="inspeccion"
              :contador="o+1"
              :operacion="'N'"
              @refrescar-pagina="reaload()"
            />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab
        title="EN CURSO"
      >
        <b-col
          v-for="(inspeccion, o) in filtrarData('EN CURSO')"
          :key="inspeccion.id"
          cols="12"
        >
          <card-inspeccion
            :id="inspeccion.id"
            :key="inspeccion.id"
            :inspeccion="inspeccion"
            :contador="o+1"
            :operacion="'U'"
            @refrescar-pagina="reaload()"
          />
        </b-col>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs, BTab, BRow, BCol,
} from 'bootstrap-vue'
import { findAmpliaciones } from '@/utils/inspecciones'
import CardInspeccion from '@/components/Inspecciones/ampliaciones/CardInspeccion.vue'

export default {
  name: 'CardsInspeccionesRutas',
  components: {
    CardInspeccion,
    BTabs,
    BTab,
    BRow,
    BCol,
  },
  data() {
    return {
      inspecciones: [],
      inspeccionesFitradas: [],
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  created() {
    this.reaload()
  },
  methods: {
    async reaload() {
      const filter = {
        where: {
          and: [
            { 'usuario.id': this.usuario.id },
            { estado: { neq: 'FINALIZADA' } },
            { estado: { neq: 'AUTORIZACION' } },
          ],
        },
        order: ['fechaCreacion DESC'],
      }
      this.inspecciones = await findAmpliaciones(filter)
    },
    filtrarData(status) {
      return this.inspecciones.filter(inspeccion => inspeccion.estado === status)
    },
  },
}
</script>

<style scoped>

</style>
