<template>
  <div>
    <b-card
      :header="`Ampliación No. ${contador}`"
      :title="inspeccion.poste"
      :footer="`${getDays()}`"
      class="text-center"
      footer-class="text-muted"
    >
      <b-row>
        <b-col cols="4">
          <span
            class="float-left"
            style="font-size: x-small"
          >Municipio</span>
        </b-col>
        <b-col cols="8">
          <b-form-input
            :value="inspeccion.municipio.nombre"
            plaintext
            size="sm"
            class="float-left"
          />
        </b-col>
        <b-col cols="4">
          <span
            class="float-left"
            style="font-size: x-small"
          >Localidad</span>
        </b-col>
        <b-col cols="8">
          <b-form-textarea
            :value="inspeccion.localidad.nombre"
            plaintext
            style="font-size: x-small"
            no-resize
            class="float-left"
          />
        </b-col>
        <b-col cols="4">
          <span
            class="float-left"
            style="font-size: x-small"
          >Calle / Avenida</span>
        </b-col>
        <b-col cols="8">
          <b-form-textarea
            :value="inspeccion.calleAvenida"
            plaintext
            no-resize
            style="font-size: x-small"
            class="float-left"
          />
        </b-col>
        <b-col cols="4">
          <span
            class="float-left"
            style="font-size: x-small"
          >Numeral</span>
        </b-col>
        <b-col cols="8">
          <b-form-textarea
            :value="inspeccion.numeral"
            plaintext
            no-resize
            style="font-size: x-small"
            class="float-left"
          />
        </b-col>
        <b-col cols="4">
          <span
            class="float-left"
            style="font-size: x-small"
          >Comentarios</span>
        </b-col>
        <b-col cols="8">
          <b-form-textarea
            :value="inspeccion.comentarios"
            plaintext
            no-resize
            style="font-size: x-small"
            class="float-left"
          />
        </b-col>
      </b-row>
      <br>
      <template>
        <b-table
          v-if="operacion !=='N'"
          responsive="sm"
          :fields="fields"
          :items="inspeccion.items"
        >
          <template #cell(item)="data">
            <span>{{ data.item.item.nombre }}</span>
          </template>
          <template #cell(seguimiento)="data">
            <modal-inspeccion
              v-if="!data.item.tracking || data.item.tracking.length < 3"
              :ampliacion="inspeccion"
              :operacion="'I'"
              :item="data.item"
              @refrescar-pagina="reaload()"
            />
            <h5
              v-else
              style="color:#C12E35;"
            >
              Completado
            </h5>
          </template>
        </b-table>
      </template>
      <modal-inspeccion
        v-if="operacion ==='N' || showFinishButton()"
        :ampliacion="inspeccion"
        :operacion="showFinishButton()? 'F' : operacion"
        @refrescar-pagina="reaload()"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCol, BRow, BFormTextarea, BFormInput, BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { diferenceBetweenToday, diferenceBetweenTodayNoAbs, formatDate } from '@/utils/fechas'
import ModalInspeccion from '@/components/Inspecciones/ampliaciones/ModalAmpliaciones.vue'

export default {
  name: 'CardInspeccionRuta',
  components: {
    BCard,
    ModalInspeccion,
    BRow,
    BCol,
    BFormTextarea,
    BFormInput,
    BTable,
  },
  directives: {
    Ripple,
  },
  props: {
    inspeccion: {
      type: Object,
      required: true,
    },
    operacion: {
      type: String,
      required: true,
    },
    contador: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      fechaCreacion: '',
      fields: ['item', 'seguimiento'],
    }
  },
  methods: {
    reaload() {
      this.$emit('refrescar-pagina')
    },
    dateFechaProgramada() {
      return formatDate(this.inspeccion.ruta.fechaProgramada, '/')
    },
    getDays() {
      const diff = diferenceBetweenTodayNoAbs(this.inspeccion.fechaCreacion)
      const realDiff = diferenceBetweenToday(this.inspeccion.fechaCreacion)
      if (diff > 0) return `Dentro de ${realDiff} día(s)`
      if (diff < 0) return `${realDiff} día(s) de atraso`
      return 'Hoy'
    },
    showFinishButton() {
      // eslint-disable-next-line no-restricted-syntax
      for (const item of this.inspeccion.items) {
        if (!item.tracking || item.tracking.length < 3) return false
      }
      return true
    },
  },
}
</script>

<style scoped>

</style>
